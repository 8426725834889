import React from "react";
import {
  Alert,
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import { Link as BrowserLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PasswordInput, PortalButton, PortalTheme } from "../core";
import { useFormik } from "formik";
import * as constants from "../core/constants";
import { API_URL } from "../core/env";
import { useAppContext, WorkerStage } from "../core/App";
import { uuid } from 'uuidv4';


const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "Register" }
)

const RegisterSchema = Yup.object().shape({
  email_address: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirm: Yup.string().oneOf([Yup.ref("password"), null], "Passwords do not match").required("You must confirm your password"),
  title: Yup.string().required("Please select a title"),
  forename: Yup.string().required("Please enter your forename"),
  surname: Yup.string().required("Please enter your surname"),
  nationality: Yup.string().required("Please select a nationality"),
  gender: Yup.string().required("Please select your gender"),
  date_of_birth: Yup.string().test("date", "Invalid date, please use DD-MM-YYYY for example 03-08-1992 for the 3rd August 1992", v => {
    const parts = v?.split("-") || [];

    if (parts.length !== 3) {
      return false;
    }

    if (parts[0].length !== 2) {
      return false;
    }

    if (parts[1].length !== 2) {
      return false;
    }

    if (parts[2].length !== 4) {
      return false;
    }

    return true
  }).required("Please enter a date of birth"),
  phone_number: Yup.string().required("Please enter a valid phone number"),
});

export const Register = () => {
  const classes = useStyles();
  const app = useAppContext();
  const [remoteError, setRemoteError] = React.useState<Maybe<string>>(null);

  const formik = useFormik({
    initialValues: {
      email_address: "",
      password: "",
      confirm: "",
      title: "",
      forename: "",
      surname: "",
      nationality: "",
      gender: "",
      date_of_birth: "",
      phone_number: "",
    },
    validationSchema: RegisterSchema,
    async onSubmit(values) {
      const dobParts = values.date_of_birth.split('-');
      fetch(`${API_URL}/api/register/worker`, {
        method: "POST",
        body: JSON.stringify({
          ...values,
          date_of_birth: `${dobParts[2]}-${dobParts[1]}-${dobParts[0]}`,
          "worker_id": uuid(),
        }),
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
      })
      .then(async res => {
        if (res.ok) {
          const data = await res.json();
          app.handlers.setAuth({token: data.token});
          app.handlers.setAccount({account_id: data.worker_id});
          app.handlers.setWorkerStage(WorkerStage.Preregistration);
        } else {
          let message = "unknown";
          try {
            const data = await res.json();
            message = data.message;
          } catch (e) {
            console.error(res);
          } finally {
            setRemoteError(message);
            window.scroll(0, 0);
          }
        }
      })
      .catch(err => {
        setRemoteError(`${err}`);
        window.scroll(0, 0);
      })
    },
  });

  return <>
    <AppBar sx={{flexGrow: 1}} position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Registration Form
        </Typography>
      </Toolbar>
    </AppBar>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Account Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The following information will be used for you to login in the future.
            </Typography>

            { remoteError ? <Alert severity="warning">{remoteError}</Alert> : null }

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.email_address && Boolean(formik.errors.email_address)}
              margin="normal"
            >
              <InputLabel htmlFor="email_address">Email</InputLabel>
              <OutlinedInput
                id="email_address"
                name="email_address"
                label="Email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email_address}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.email_address && Boolean(formik.errors.email_address)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.email_address}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.password && Boolean(formik.errors.password)}
              margin="normal"
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <PasswordInput
                id="password"
                name="password"
                label="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.password && Boolean(formik.errors.password)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.password}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.confirm && Boolean(formik.errors.confirm)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Confirm</InputLabel>
              <PasswordInput
                id="confirm"
                name="confirm"
                label="Confirm"
                onChange={formik.handleChange}
                value={formik.values.confirm}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.confirm && Boolean(formik.errors.confirm)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.confirm}
                </FormHelperText>
              )}
            </FormControl>
            
            <Divider className={classes.divider} />
            <Typography gutterBottom variant="h5" component="div">
              Personal Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please provide accurate details about yourself.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.title && Boolean(formik.errors.title)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Title</InputLabel>
              <Select
                id="title"
                name="title"
                label="Title"
                onChange={formik.handleChange}
                value={formik.values.title}
                disabled={formik.isSubmitting}
              >
                { constants.TITLES.map((v, i: number) => (
                  <MenuItem key={`title-${i}`} value={v.value}>{v.label}</MenuItem>
                )) }
              </Select>
              {(formik.touched.title && Boolean(formik.errors.title)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.title}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.forename && Boolean(formik.errors.forename)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Forename</InputLabel>
              <OutlinedInput
                id="forename"
                name="forename"
                label="Forename"
                onChange={formik.handleChange}
                value={formik.values.forename}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.forename && Boolean(formik.errors.forename)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.forename}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.surname && Boolean(formik.errors.surname)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Surname</InputLabel>
              <OutlinedInput
                id="surname"
                name="surname"
                label="Surname"
                onChange={formik.handleChange}
                value={formik.values.surname}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.surname && Boolean(formik.errors.surname)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.surname}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.nationality && Boolean(formik.errors.nationality)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Nationality</InputLabel>
              <Select
                id="nationality"
                name="nationality"
                label="Nationality"
                onChange={formik.handleChange}
                value={formik.values.nationality}
                disabled={formik.isSubmitting}
              >
                {constants.NATIONALITIES.map((v, i: number) => (
                  <MenuItem key={`nationality-${i}`} value={v.value}>{v.label}</MenuItem>
                ))}
              </Select>
              {(formik.touched.nationality && Boolean(formik.errors.nationality)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.nationality}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Gender</InputLabel>
              <Select
                id="gender"
                name="gender"
                label="Gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                disabled={formik.isSubmitting}
              >
                {constants.GENDERS.map((v, i: number) => (
                  <MenuItem key={`gender-${i}`} value={v.value}>{v.label}</MenuItem>
                ))}
              </Select>
              {(formik.touched.gender && Boolean(formik.errors.gender)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.gender}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Date of Birth (DD-MM-YYYY)</InputLabel>
              <OutlinedInput
                id="date_of_birth"
                name="date_of_birth"
                label="Date of Birth (DD-MM-YYYY)"
                onChange={formik.handleChange}
                value={formik.values.date_of_birth}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.date_of_birth}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
              margin="normal"
            >
              <InputLabel htmlFor="confirm">Phone Number</InputLabel>
              <OutlinedInput
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                onChange={formik.handleChange}
                value={formik.values.phone_number}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.phone_number && Boolean(formik.errors.phone_number)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.phone_number}
                </FormHelperText>
              )}
            </FormControl>
          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
