import React from "react";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  "question-1": string,
  "question-2": string,
  "question-3": string,
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    "question-1": "",
    "question-2": "",
    "question-3": "",
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h5" component="div">Anti-Modern Slavery</Typography>
    <Typography component="div" variant="body1">
      At GTR Recruitment we do everything possible to minimise the opportunity that criminals have in exploiting individuals.
      With particular reference to modern-slavery we ensure that
      <br/>
      <ul>
        <li>All workers have their own bank accounts.</li>
        <li>We will never charge for our services.</li>
        <li>You should never pay any company or person for finding you work.</li>
      </ul>
      Our policy is <a href="http://gtrrecruitment.com/modern-slavery-statement" target="_blank" rel="noopener noreferrer">here</a> – this explains in detail what we do to protect you.
      <br/>
      To minimise the risk to you and others, we need to ask you the following questions.
    </Typography>
    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
    <Typography variant="h5">Please answer the following:</Typography>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Does someone else hold your bank account for you?</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-1" value={values["question-1"]} onChange={(e)=> setValue("question-1", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Is your ID usually held by someone else?</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-2" value={values["question-2"]} onChange={(e)=> setValue("question-2", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Have you ever been asked by anyone to pay them for helping you find a job?</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-3" value={values["question-3"]} onChange={(e)=> setValue("question-3", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
