import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  "question-1": string,
  "question-2": string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    "question-1": "",
    "question-2": "no",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <FormLabel component="legend">Read the following statements carefully and check the box that applies to you.</FormLabel>
    <FormControl component="fieldset" fullWidth>
      <RadioGroup name="question-1" value={values["question-1"]} onChange={(e)=> setValue("question-1", e.target.value)}>
        <FormControlLabel value="1" control={<Radio />} label="This is my first job since last 6 April and I have not been receiving taxable Jobseeker's Allowance, Employment and Support Allowance or taxable Incapacity Benefit or a state or occupational pension" />
        <FormControlLabel value="2" control={<Radio />} label="This is now my only job, but since last 6 April I have had another job, or have received taxable Jobseeker's Allowance, Employment and Support Allowance or Incapacity Benefit. I do not receive a state or occupational pension" />
        <FormControlLabel value="3" control={<Radio />} label="I have another job or receive a state or occupational pension." />
      </RadioGroup>
    </FormControl>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Student Loans (ADVANCED IN UK)</FormLabel>
      <Typography variant="body1">
      Please select "Yes" if you left a course of Higher Education before last 6 April and received your first Student Loan
      instalment on or after 1 September 1998 and you have not fully repaid your Student Loan.

      If you are required to repay your Student Loan through your bank or building society account please chose "No"
      </Typography>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-2" value={values["question-2"]} onChange={(e)=> setValue("question-2", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
