import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  "question-1": string,
  "question-2": string,
  "question-3": string,
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    "question-1": "",
    "question-2": "",
    "question-3": "",
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h4" component="div">Manual Handling Guide</Typography>
    <img alt="" src="https://storage.googleapis.com/acceptrec-legal/manual-handling-one.png" />
    <img alt="" src="https://storage.googleapis.com/acceptrec-legal/manual-handling-two.jpg" />
    <Typography variant="h6">Introduction</Typography>
    <Typography variant="body1">
      When you’re lifting, or stacking, or moving things about, you’re not just using your hands.  You’re using all the tools at your disposal, all the muscles, joints and ligaments in y  our body.  People with manual handling tasks use these tools every day.  But like anything you use frequently, those tools –these muscles, joints and ligaments get taken for granted. That’s when accidents happen.
    </Typography>
    <br/>
    <Typography variant="h5">There are a few simple rules to remember when handling loads</Typography>
    <br/>
    <Typography variant="h6">Stop &amp; Think</Typography>
    <Typography variant="body1">
      Plan the lift. Where is the load going to be placed? Use the appropriate handling aids if possible. Do you need help with the load? Remove obstructions such as discarded wrapping materials. For a long lift - such as floor to shoulder height - consider resting the load mid-way on a table or bench in order to change grip.
    </Typography>

    <Typography variant="h6">Keep It Close to the Body</Typography>
    <Typography variant="body1">
      The further away the load, the more stress on your lower back. Holding a load at arms length puts five times more weight on your back than holding it close to you. Keeping: it close to your chest makes you more stable - and the friction of your clothes helps keep the weight where you-want it.
    </Typography>

    <Typography variant="h6">Check your feet - Place the feet apart</Typography>
    <Typography variant="body1">
      Get close to the job: stand square on to the load, with your leading leg as far forward as comfortable, preferably facing the direction you're going to move in next.
    </Typography>

    <Typography variant="h6">Open your Legs</Typography>
    <Typography variant="body1">
      If you have to bend down to pick something up, don’t stoop – bend the legs, and use your strong leg muscles to take the weight. It's not a good idea to handle loads when sitting down - you can't use your leg muscles, you can't use your body weight as a counter balance, and you're asking too much of your arms and upper body.
    </Typography>

    <Typography variant="h6">Pulling and Pushing Power</Typography>
    <Typography variant="body1">
      You have more power when pulling or pushing if your footing is secure - make sure the floor is dry and solid.  Grip the load between waist and shoulder to ease, strain on the back and the arms. Even better, turn your back to it and push with your legs: you have strong leg muscles for a reason.
    </Typography>

    <Typography variant="h6">Is It Too Big?</Typography>
    <Typography variant="body1">
      Make sure that the load is small enough to enable you to get a good grip, and see where you're going. If any side of what you are carrying -length, width or height - is more than 75 crn then you run a greater chance of injuring yourself. Make sure you know where the centre of gravity is; keep the heaviest side nearest to your body.
    </Typography>

    <Typography variant="h6">Not too far</Typography>
    <Typography variant="body1">
      If you can lift a load and carry it easily against your body, you'll also be able to carry it safely. Don't carry it too far, however: more than 10m and you 'II probably be using all your energy in carrying the load, and have none left to put it down safely.
    </Typography>

    <Typography variant="h6">How much Weight?</Typography>
    <Typography variant="body1">
      It is difficult to give precise guidelines about how much weight people should be carrying because people vary so much. Weight is only one of the risk factors to manual handlers. The following diagram shows the guideline for lifting and lowering weights that men and women should be carrying safely.
    </Typography>

    <Typography variant="h6">Don’t Twist</Typography>
    <Typography variant="body1">
      You could hurt your back.  Lift, carry and place in one direction where possible.  If you have to put a load in an exact position, put it down first, then adjust it, when the weight is off you.
    </Typography>

    <Typography variant="h6">Team Handling</Typography>
    <Typography variant="body1">
      Difficulties may arise if team members impede each other vision or movement, or if the lad offers insufficient good handholds.  As most of your work will be carried out in teams it is imperative that both work together as a team!
    </Typography>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
