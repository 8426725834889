import React from "react";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h4" component="div">Work Finder Agreement</Typography>
    <Typography component="div" variant="body2">
      This Work Finder Agreement is between GTR TRAINING SERVICES LTD trading as
      GTR Recruitment incorporated and registered in England and Wales with company
      number 12148334 whose registered office is at 22 Dartford Road, Leicester, Leicestershire, United Kingdom, LE2 7PQ
      (the ‘Company’) and you the worker (referred to here as the Candidate or ‘you’;
      ‘your’ is to be construed accordingly).
    </Typography>

    <Typography variant="body1">
      The Company has been asked by you to seek work on your behalf. All work-seeking
      services provided by the Company to you will be provided in-line with this Agreement.
      By signing this agreement, you agree to the Terms of Agreement set out below.
      You should ensure you read and understand the agreement before signing.
    </Typography>

    <Divider sx={{ marginTop: 1, marginBottom: 1 }}/>

    <Typography variant="h5">TERMS OF AGREEMENT</Typography>

    <ol>
      <li>The Company is to provide you with either recruitment services; that is to say, the Company will act as an agency as defined under the Employment Agencies Act 1973 and/or an Employment Business as defined by the Conduct of Employment Agencies and Employment Businesses Regulations 2003 (“the Conduct Regs”).</li>
      <li>You authorise the Company to seek work on your behalf.</li>
      <li>In the event you no longer want the Company to provide you with work-findings services, you must provide 7 days’ notice in writing confirming this.</li>
      <li>This Agreement is for work-finding services only. This Agreement does not in any way constitute a contract for services or a contract of employment. You understand that when or if the Company finds you any work, any contract for services or contract of employment is likely to be with a third party and not with the Company.</li>
      <li>For works undertaken by you the Company will ensure that you always receive payment in accordance with the Conduct Regs.</li>
      <li>This Agreement is not a guarantee that work will be found for you. No liability is accepted if work cannot be identified, nor is it guaranteed that any work will be suitable for you.</li>
      <li>You will receive at least the National Minimum Wage or National Minimum Wage for any work that you have undertaken. Further details of the rate of pay you will receive will be accessible from the business that you have your contract of employment or contract for services with (the ‘Engager’). The Engager can either be the Company or a third-party business.</li>
      <li>Under normal circumstances, you will be paid for your work on a weekly basis. Exact details of when you will be paid will be accessible from the Engager.</li>
      <li>You will be eligible to not less that the statutory leave entitlement. Further details of your exact entitlement to holidays and payment in respect of holidays will be accessible from the Engager.</li>
      <li>You agree to the Company using all information provided by you, including any   documents provided by you or a third party on your behalf for the purpose of complying with any obligations, statutory or otherwise, that the Company may have and for the purpose of seeking work for you.  This includes but is not limited to providing such information or documents to the Company’s clients or a third-party employer.  You understand that you may withdraw your consent in writing at any time, in which case the Company will only then comply with its legal obligations in providing information.</li>
      <li>The Company will process personal data (including special categories of personal data and criminal records personal data) relating to you in accordance with our data protection policy and the privacy notice which can be viewed at www.acceptrec.co.uk  or alternatively you can obtain a copy by contacting 01162182133</li>
      <li>In conjunction with clause 11, you agree that if the Company identifies suitable work for you, the Company may introduce you (or process your personal data) to a third-party organisation, who may act as your employer and for who you will contract with directly. Further, you agree with and acknowledge that any third party to whom you are introduced and with whom the Company may have a contract with, shall be permitted to subcontract, delegate, or assign its responsibilities and obligations, such that you may be required to contract directly with a different third party in respect of providing services.</li>
      <li>In the instance that you do not want information about you to be held by the Company, you will provide the Company with written confirmation.</li>
      <li>Relating to its subject matter, this Agreement constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representatives and understandings between them, whether written or oral.</li>
      <li>This Agreement may not be varied, save by agreement by both parties in writing, which in the case the Company is required by a statutory director.</li>
      <li>If any provision or part provision of this Agreement is invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable.  If such modification is not possible, the relevant provision or part provision shall be deemed deleted.  Any modification to or deletion of a provision or part provision under this term shall not affect the validity or enforceability of the rest of this Agreement.</li>
      <li>This agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.</li>
      <li>Each party irrevocably agrees that the courts of England and Wales have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).</li>
    </ol>

    <Typography variant="body1">
      By signing, you are acknowledging that you agree to and accept the terms of this Agreement.
    </Typography>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
