import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  "question-1": string,
  "question-1-text": string,
  "question-2": string,
  "question-2-text": string,
  "question-3": string,
  "question-3-text": string,
  "question-4": string,
  "question-4-text": string,
  "question-5": string,
  "question-5-text": string,
  "question-6": string,
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    "question-1": "no",
    "question-1-text": "",
    "question-2": "no",
    "question-2-text": "",
    "question-3": "no",
    "question-3-text": "",
    "question-4": "no",
    "question-4-text": "",
    "question-5": "no",
    "question-5-text": "",
    "question-6": "",
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
  <Typography gutterBottom variant="h4" component="div">Your present health circumstances</Typography>
    <Typography gutterBottom variant="h5" component="div">Please answer the following:</Typography>

<div className={classes.fieldGroup}>
  <FormLabel component="legend">Do you have any health-related condition that will require reasonable adjustments to be made to the selection process?</FormLabel>
  <FormControl component="fieldset" fullWidth>
    <RadioGroup name="question-1" value={values["question-1"]} onChange={(e)=> setValue("question-1", e.target.value)}>
      <FormControlLabel value="no" control={<Radio />} label="No" />
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
    </RadioGroup>
    <Typography variant="body2">If yes, please specify details:</Typography>
    <TextField
      value={values["question-1-text"]}
      onChange={(e) => setValue("question-1-text", e.target.value)}
      disabled={values["question-1"] === "no"}
      fullWidth
      multiline
      rows={4}
    />
  </FormControl>
</div>

<div className={classes.fieldGroup}>
  <FormLabel component="legend">Taking into account the work that you are applying for, do you have any health-related condition that may affect your ability to do the work?</FormLabel>
  <FormControl component="fieldset" fullWidth>
    <RadioGroup name="question-2" value={values["question-2"]} onChange={(e)=> setValue("question-2", e.target.value)}>
      <FormControlLabel value="no" control={<Radio />} label="No" />
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
    </RadioGroup>
    <Typography variant="body2">If yes, please specify details:</Typography>
    <TextField
      value={values["question-2-text"]}
      onChange={(e) => setValue("question-2-text", e.target.value)}
      disabled={values["question-2"] === "no"}
      fullWidth
      multiline
      rows={4}
    />
  </FormControl>
</div>

<div className={classes.fieldGroup}>
  <FormLabel component="legend">At present, or in the last seven days have you had diarrhoea, vomiting, stomach pain, nausea, jaundice or fever? or suffered from skin infections of the hands, arms or face or discharge from eye, ear, gums or mouth?</FormLabel>
  <FormControl component="fieldset" fullWidth>
    <RadioGroup name="question-3" value={values["question-3"]} onChange={(e)=> setValue("question-3", e.target.value)}>
      <FormControlLabel value="no" control={<Radio />} label="No" />
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
    </RadioGroup>
    <Typography variant="body2">If yes, please specify details:</Typography>
    <TextField
      value={values["question-3-text"]}
      onChange={(e) => setValue("question-3-text", e.target.value)}
      disabled={values["question-3"] === "no"}
      fullWidth
      multiline
      rows={4}
    />
  </FormControl>
</div>

<div className={classes.fieldGroup}>
  <FormLabel component="legend">Do you suffer from recurring bowel disorders, skin, ear or throat infections?</FormLabel>
  <FormControl component="fieldset" fullWidth>
    <RadioGroup name="question-4" value={values["question-4"]} onChange={(e)=> setValue("question-4", e.target.value)}>
      <FormControlLabel value="no" control={<Radio />} label="No" />
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
    </RadioGroup>
    <Typography variant="body2">If yes, please specify details:</Typography>
    <TextField
      value={values["question-4-text"]}
      onChange={(e) => setValue("question-4-text", e.target.value)}
      disabled={values["question-4"] === "no"}
      fullWidth
      multiline
      rows={4}
    />
  </FormControl>
</div>

<div className={classes.fieldGroup}>
  <FormLabel component="legend">Have you ever had typhoid or paratyphoid fever? Or are you now known to be a carrier of any type of Salmonella? Or In the last 21 days have you had contact with anyone who may have been suffering from typhoid, paratyphoid or Salmonella?</FormLabel>
  <FormControl component="fieldset" fullWidth>
    <RadioGroup name="question-5" value={values["question-5"]} onChange={(e)=> setValue("question-5", e.target.value)}>
      <FormControlLabel value="no" control={<Radio />} label="No" />
      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
    </RadioGroup>
    <Typography variant="body2">If yes, please specify details:</Typography>
    <TextField
      value={values["question-5-text"]}
      onChange={(e) => setValue("question-5-text", e.target.value)}
      disabled={values["question-5"] === "no"}
      fullWidth
      multiline
      rows={4}
    />
  </FormControl>
</div>

<div className={classes.fieldGroup}>
  <FormLabel component="legend">Please provide a list of countries you've visited in the last 6 weeks:</FormLabel>
  <FormControl component="fieldset" fullWidth>
    <TextField
      value={values["question-6"]}
      onChange={(e) => setValue("question-6", e.target.value)}
      fullWidth
      multiline
      rows={6}
    />
  </FormControl>
</div>

<div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
