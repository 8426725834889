import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormRadioList, PortalTheme } from "../core";
import { DOCUMENTS, DocumentTypeCollection } from "../core/constants";
import { uploadFile } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    fileField: {
      margin: theme.spacing(2, 0),
      width: "100%",
    }
  }),
  { name: "UploadDocuments" }
)

type UploadDocumentsProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

type UploadDocumentProps = {
  token: string,
  workerId: string,
  types: DocumentTypeCollection,
  onSuccess: () => void,
};


export const UploadDocument: React.FC<UploadDocumentProps> = ({
  token,
  workerId,
  types,
  onSuccess,
}) => {
  const classes = useStyles();

  const [fileType, setFileType] = useState<string>(types[0].value);
  const [fileObj, setFileObj] = useState<Maybe<File>>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const upload = () => {
    setUploading(true)
    uploadFile(token, workerId, fileType, fileObj)
    .then(onSuccess)
    .catch(err => {
      console.error(err);
      alert("There was an error uploading, please try again.");
      setUploading(false);
    })
  };

  return <>
    <FormRadioList
      label="Please select a type of document you would like to upload."
      list={types}
      value={fileType}
      onChange={e => setFileType(e.target.value)}
      listLength={types.length}
    />
    <Divider className={classes.divider} />
    <input disabled={uploading} type="file" onChange={e => setFileObj(e.target.files![0]!)} className={classes.fileField} />
    {
      fileObj === null ?
      <Typography>Please select a file.</Typography>
      : <Button disabled={uploading} variant="contained" className={classes.button} onClick={upload}>Upload</Button>
    }
  </>
};


export const UploadDocuments: React.FC<UploadDocumentsProps> = ({
  token,
  workerId,
  onSuccess,
}) => {
  const [documentNum, setDocumentNum] = useState<number>(1);

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <Card>
        <CardContent>
          <Typography variant="h5">Uploading document {documentNum} of 2</Typography>

          <UploadDocument
            key={documentNum}
            token={token}
            workerId={workerId}
            types={DOCUMENTS}
            onSuccess={() => {
              if (documentNum === 2) {
                onSuccess();
              }

              setDocumentNum(documentNum+1);
            }}
          />
        </CardContent>
      </Card>
      <CardActions>
        <Button onClick={onSuccess}>Skip, you can upload documents later</Button>
      </CardActions>
    </Stack>
  </>
};
