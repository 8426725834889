import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h5" component="div">Transport Policy</Typography>
    <Typography component="div" variant="body1">
      The below is to clarify the transport arrangements for those workers who require the use of the transport service provided by GTR Recruitment.
    </Typography>

    <Typography component="div" variant="body1">
      It is the policy of the GTR Recruitment to offer transport to all workers traveling to and from client's premises. The use of the transport services is completely optional, and you have free choice to use any means of traveling to and from work.
    </Typography>

    <Typography component="div" variant="body1">
    For the use of transport provided by GTR Recruitment, there will be a charge of £7.00 per day.
    </Typography>

    <Typography component="div" variant="body1">
    You may pay either by cash to the driver or by setting up a direct debit payment.
    </Typography>

    <Typography component="div" variant="body1">
      Should you wish you, you may request a loan of £7.00, which you are free to do with as you wish, and should you wish to use the transport provided by GTR Recruitment , you may be able to use this money to pay the driver for the transport services provided.
    </Typography>

    <Typography component="div" variant="body1">
      To administer the cost of transport the following procedure will apply:
    </Typography>

    <ul>
      <li>Signing to confirm the understanding of the transport policy at the bottom of this form</li>
      <li>Signing and confirming the type of payment to be made for the use of transport on the daily transport form</li>
      <li>If a loan has been made you will be asked to sign the form authorising the company to deduct the loan from your next wage payment processed.</li>
    </ul>

    <Typography component="div" variant="body1">
      I would like to confirm that I fully understand the transport policy and agree that the cost of the transport service provided by GTR Recruitment will be £7.00 per day, and I can choose to pay by cash or direct debit.
    </Typography>

    <Typography component="div" variant="body1">
      I also understand GTR Recruitment will allow me a small load of £7.00 (an advance on  my wages), which I can do as I wish, as well as using this pay for the transport services provided*
    </Typography>
    <small>*once small loan taken for transportation you will need to commit to work, if you change your mind regarding your attendance you would need to let GTR Recruitment know by 8pm on the day when you have been booked if you failed to follow the procedure GTR Recruitment will deduct money for loan taken.</small>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
