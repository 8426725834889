import React from "react";
import {
  CircularProgress,
  Stack,
} from "@mui/material";

export const Loading = () => (
<>
  <Stack alignItems="center" sx={{marginTop: 3}}>
    <CircularProgress color="success" />
  </Stack>
</>);
