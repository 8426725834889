import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Login, ChooseAction, Register } from "./auth";
import { useAppContext, WorkerStage } from "./core/App";
import { PreregistrationApp } from "./preregistration/App";
import { DashboardApp } from "./dashboard/App";

export const App = () => {
  const app = useAppContext();

  if (app.workerStage === null) {
    return null;
  }

  if (app.workerStage === WorkerStage.Complete) {
    return <DashboardApp />;
  }

  if (app.workerStage === WorkerStage.Preregistration) {
    return <PreregistrationApp />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth/login">
          <Login />
        </Route>
        <Route path="/auth/register">
          <Register />
        </Route>
        <Route path="/welcome">
          <ChooseAction />
        </Route>
        <Route>
          <Redirect to="/welcome"/>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
