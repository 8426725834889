import { AppContext } from ".";
import { useContext } from "react";

export function useAppContext() {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error("Using AppContext outside of the provider.");
  }

  return appContext;
}
