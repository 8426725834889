import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  "question-1": string,
  "question-1-text": string,
  "question-2": string,
  "question-2-text": string,
  "question-3": string,
  "question-3-text": string,
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    "question-1": "no",
    "question-1-text": "",
    "question-2": "no",
    "question-2-text": "",
    "question-3": "no",
    "question-3-text": "",
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h4" component="div">Information and Guidelines - Coronavirus</Typography>
    <Typography component="div" variant="body1">
      In light of the recent outbreaks of the Coronavirus in Wuhan China and confirmed cases elsewhere, we have sought guidance from Public Health England and have outlined below information based on their information and our own Health and Hygiene procedures.
      If Visitors have visited WUHAN China, they should follow Official advice and get the all clear from a medical practitioner before mixing in the General Public. If anyone returning from other affected areas are feeling unwell, they should still seek advice before mixing in general public.
      <br/>
      The General advice is that at present the risk to public health is deemed as moderate by Public Health England.
      <br/>
      Advice for travelers returning from affected areas:
      <ul>
        <li>Stay indoors and avoid contact with others.</li>
        <li>Contact NHS 111 to inform them of recent travel.</li>
        <li>Tests may be carried out for the COVID-19 if deemed necessary.</li>
      </ul>
      A team of public health officials are based at Airports to support travelers from China and affected areas.
      <br/>
      If anyone who has visited these areas, develops a fever, difficulty breathing or a cough, they should continue to follow the advice above and not leave your home until advised by a clinician whilst awaiting test results.
      <br/>
      Infection Control Measures are in place at nursery to reduce the spread of all viruses, as always with high levels of personal hygiene.
      <ul>
        <li>Hand hygiene is the first and most important line of defence.</li>
        <li>Like cold and flu bugs, the new virus is thought to be spread via droplets when a person coughs or sneezes. The droplets land on surfaces and are picked up on the hands of others and spread further. People catch the virus when they touch their infected hands to their mouth, nose or eyes.</li>
        <li>The single most important thing you can do to protect yourself is to keep your hands clean by washing them frequently with soap and water and use of hand sanitizing gel.</li>
        <li>Use of tissues when coughing, sneezing and wiping nose, and appropriate disposal.</li>
        <li>Ensure regular cleaning of nursery environments, frequently disinfecting surfaces and all touch points (door handles, taps, work surfaces toys and equipment)</li>
        <li>Try to avoid touching your mouth, nose or eyes with unwashed hands – something we all do unconsciously (on average about 15 times an hour).</li>
      </ul>
    </Typography>
    <Typography variant="h6">Please answer the following:</Typography>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Have you had a cough, height temperature or shortness of breath?</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-1" value={values["question-1"]} onChange={(e)=> setValue("question-1", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-1-text"]}
          onChange={(e) => setValue("question-1-text", e.target.value)}
          disabled={values["question-1"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Have you been in close contact with someone who has been diagnosed with coronavirus?</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-2" value={values["question-2"]} onChange={(e)=> setValue("question-2", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-2-text"]}
          onChange={(e) => setValue("question-2-text", e.target.value)}
          disabled={values["question-2"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Have you travelled anywhere in the past 21 days? If so, please specify.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-3" value={values["question-3"]} onChange={(e)=> setValue("question-3", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-3-text"]}
          onChange={(e) => setValue("question-3-text", e.target.value)}
          disabled={values["question-3"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
