import { createTheme } from "@mui/material/styles";
import { shadows } from "./shadows";

type ThemeOptions = import("@mui/material").ThemeOptions;
type Theme = import("@mui/material").Theme;

type Custom = {
  custom: {
    hint: string;
  };
};

type CustomOptions = Partial<Custom>;
type CustomThemeOptions = CustomOptions & ThemeOptions;

export type PortalTheme = Theme & Custom;

const options: CustomThemeOptions = {
  custom: {
    hint: "rgba(10, 30, 65, .38)",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        containedSizeLarge: {
          padding: "12px 22px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        notchedOutline: {
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderWidth: 2,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#222222",
    },
    secondary: {
      main: "rgb(26, 157, 128)",
    },
    background: {
      default: "#f4f5f7",
    },
    text: {
      primary: "rgba(10, 30, 65, .87)",
      secondary: "rgba(10, 30, 65, .54)",
      disabled: "rgba(10, 30, 65, .38)",
    },
  },
  shadows,
};

export const muiTheme = createTheme(options);
