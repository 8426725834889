import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  verticalTable: {
    border: '1px solid #dedede',

    '& td, th': {
      padding: theme.spacing(2),
    },

    '& tr:not(:last-child) td': {
      borderBottom: '1px solid #dedede',
    },

    '& thead th': {
      background: '#dedede',
    }
  },
}));

type AgreementSubmissionPayload = {
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h5" component="div">Deducations</Typography>
    <Typography component="div" variant="body1">
      I agree that I will complete a PPE Agreement every time a piece of personal protective equipment (PPE) is issued to me or returned by me during the course of or at the end of an assignment.
    </Typography>
    <Typography component="div" variant="body1">
      <strong>Please note, these charges are only enforced should PPE not be returned to GTR Recruitment at the end of your assignment.</strong>
    </Typography>
    <br/>
    <table className={classes.verticalTable}>
      <thead>
        <tr>
          <th>Item of PPE</th>
          <th>Total Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Steel lite safety boots (EACH)</td>
          <td>£30.00</td>
        </tr>
        <tr>
          <td>Hi Viz vest with logo (EACH)</td>
          <td>£5.00</td>
        </tr>
        <tr>
          <td>Polo neck logo black T-shirt</td>
          <td>£6.50</td>
        </tr>
        <tr>
          <td>Fleece</td>
          <td>£10.00</td>
        </tr>
        <tr>
          <td>ID Badge</td>
          <td>£2.00</td>
        </tr>
        <tr>
          <td>Key Fob</td>
          <td>£16.00</td>
        </tr>
        <tr>
          <td>Lanyard</td>
          <td>£2.00</td>
        </tr>
      </tbody>
    </table>
    <br/>
    <Typography component="div" variant="body1">
      I must upon request and, in any event, on the termination of my employment immediately return to GTR Recruitment any property belonging to GTR Recruitment or the company I was placed at. A failure to do so will result in GTR Recruitment deducting monies from my final pay or outstanding holiday pay to cover the cost of these items. Additionally, GTR Recruitment reserves the right to deduct any money for wilful damage to or loss of company property.
    </Typography>
    <br/>
    <br/>
    <br/>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
