import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  root: {
    maxWidth: "800px",
    margin: "0 auto 25px",
  },
  coreCard: {
    width: "100%",
    marginBottom: "25px",
  },
  section: {
    '& fieldset': {
      marginTop: theme.spacing(2),
    },
    '& table': {
      width: '100%',

      '& th, td': {
        textAlign: 'center',
      }
    },
  },
  sectionBrief: {
    marginBottom: theme.spacing(2),
  },
  sectionQuestion: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #eee',
  },
}));

type ScoreCardDataSectionQuestion = {
  body: string,
  type: "single_choice" | "multiple_choice",
  label: string,
  options: string[],
  answers_required: number,
};

type ScoreCardDataSection = {
  brief: string,
  label: string,
  questions: ScoreCardDataSectionQuestion[],
};

type ScoreCardData = {
  score_card_id: string,
  started_at: number,
  assessment_id: string,
  label: string,
  brief: string,
  pass_mark: number,
  skills: string[],
  sections: ScoreCardDataSection[],
}

type Question = {
  type: string,
  label: string,
  answers_required?: number,
  options: string[],
}

type RenderOptionsProps = {
  sectionIndex: number,
  answers: any,
  setAnswer: (question: string, value: Boolean | number, offset?: number) => void,
  questionIndex: number,
  question: Question,
};

const RenderOptions: React.FC<RenderOptionsProps> = ({
  sectionIndex,
  answers,
  setAnswer,
  questionIndex,
  question,
}) => {
  const a = answers[`${sectionIndex}__${questionIndex}`] || [];

  if (question.type === 'multiple_choice') {
    return <React.Fragment>
      <Typography variant="caption">Please select {question.answers_required} answers.</Typography>
      { question.options.map((o, oi) => <FormControlLabel
        key={oi}
        value={`${oi}`}
        control={<Checkbox />}
        label={`${o}`}
        onChange={(e: any) => setAnswer(`${sectionIndex}__${questionIndex}`, e.target.checked, oi)}
        checked={a.indexOf(oi) > -1}
      />) }
    </React.Fragment>
  }

  return <RadioGroup
      aria-label={question.label}
      name={`question_${sectionIndex}__${questionIndex}`}
      value={`${a.length > 0 ? a[0] : -1}`}
      onChange={(e) => setAnswer(`${sectionIndex}__${questionIndex}`, +e.target.value)}
    >
      { question.options.map((o, oi) => <FormControlLabel key={oi} value={`${oi}`} control={<Radio />} label={`${o}`} />) }
    </RadioGroup>;
}

type SectionProps = {
  data: ScoreCardDataSection,
  sectionIndex: number,
  answers: Map<string, any>,
  setAnswer: (question: string, value: Boolean | number, offset?: number) => void,
};

const Section: React.FC<SectionProps> = ({data, sectionIndex, answers, setAnswer}) => {
  const classes = useStyles();
  return <>
    <Card className={classes.coreCard} elevation={0} square>
      {data.label !== "-" ? <CardHeader title={data.label} /> : null}
      <CardContent>
          <div className={classes.section}>
            <div className={classes.sectionBrief}>
              <ReactMarkdown children={data.brief} remarkPlugins={[remarkGfm]} />
            </div>
            {
              data.questions.map((question, i) => (
                <div key={i} className={classes.sectionQuestion}>
                <FormLabel component="legend">{question.label}</FormLabel>
                  {question.body ? <ReactMarkdown remarkPlugins={[remarkGfm]}>{question.body}</ReactMarkdown> : null}
                  <FormControl component="fieldset">
                    <RenderOptions question={question} sectionIndex={sectionIndex} questionIndex={i} answers={answers} setAnswer={setAnswer} />
                  </FormControl>
                </div>
              ))
            }
          </div>
      </CardContent>
    </Card>
  </>;
};

type TakeScoreCardPayload = any;

export type TakeScoreCardHandler = (payload: TakeScoreCardPayload) => void;

type DisplayScoreCardProps = {
  scoreCard: ScoreCardData,
  submissionHandler: TakeScoreCardHandler,
};

export const DisplayScoreCard: React.FC<DisplayScoreCardProps> = ({
  scoreCard,
  submissionHandler,
}) => {
  const [answers, setAnswers] = React.useState<TakeScoreCardPayload>({});

  const setAnswer = (question: string, value: Boolean | number, offset?: number) => {
    if (offset === undefined) {
      setAnswers({...answers, [question]: [value]})
    } else {
      let values: number[] = answers[question] || [];
      if (value) {
        values.push(offset);
      } else {
        values = values.filter(i => i !== offset);
      }
      setAnswers({...answers, [question]: values})
    }
  };

  const [sending, setSending] = React.useState<boolean>(false);

  return <>
    {
      scoreCard.sections.map((s, i) => (<Section
        key={`score-card-section-${i}`}
        data={s}
        sectionIndex={i}
        answers={answers}
        setAnswer={setAnswer}
      />))
    }

    <Button
      variant="contained"
      disabled={sending}
      onClick={() => {
        setSending(true);
        submissionHandler(answers);
      }}
    >Submit</Button>
  </>
};
