import { PortalButton, PasswordInput, PortalTheme } from "../core";
import {
  getEmailFromCookie,
  removeEmailFromCookie,
  storeEmailInCookie,
} from "../core/Auth";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link as BrowserLink } from "react-router-dom";
import * as Yup from "yup";
import { AuthWrapper } from "./AuthWrapper";
import { useAuthenticate } from "./useAuthenticate";

/**
 * Login component styles
 */
const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
  }),
  { name: "Login" }
);

/**
 * Validation schema for login form.
 */
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  remember: Yup.bool(),
});

/**
 * Login component props
 */
export type LoginProps = {};

export const Login: React.FC<LoginProps> = () => {
  const classes = useStyles();
  const [withAuthenticateError, setWithAuthenticateError] = useState<string>();
  const cookieEmail = getEmailFromCookie();
  const authenticate = useAuthenticate();

  const formik = useFormik({
    initialValues: {
      email: cookieEmail ?? "",
      password: "",
      remember: cookieEmail ? true : false,
    },
    validationSchema: LoginSchema,
    async onSubmit(values) {
      try {
        await authenticate(values.email, values.password);

        if (values.remember) {
          storeEmailInCookie(values.email);
        } else {
          removeEmailFromCookie();
        }
      } catch (e: any) {
        setWithAuthenticateError(e);
      }
    },
  });

  // has email field been used and with valid input
  const isEmailInvalid = formik.touched.email && Boolean(formik.errors.email);

  // has password field been used and with valid input
  const isPasswordInvalid =
    formik.touched.password && Boolean(formik.errors.password);

  return (
    <AuthWrapper>
      <form onSubmit={formik.handleSubmit}>
        {withAuthenticateError && (
          <Alert className={classes.invalidError} severity="error">
            {withAuthenticateError}
          </Alert>
        )}
        <FormControl
          className={classes.textField}
          fullWidth
          variant="outlined"
          error={isEmailInvalid}
          margin="normal"
        >
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            id="email"
            name="email"
            label="Email"
            type="email"
            autoComplete="username"
            onChange={formik.handleChange}
            value={formik.values.email}
            disabled={formik.isSubmitting}
          />
          {isEmailInvalid && (
            <FormHelperText variant="outlined">
              {formik.errors.email}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          className={classes.textField}
          variant="outlined"
          error={isPasswordInvalid}
          fullWidth
          margin="normal"
        >
          <InputLabel htmlFor="password">Password</InputLabel>
          <PasswordInput
            id="password"
            name="password"
            label="Password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.password}
            disabled={formik.isSubmitting}
          />
          {isPasswordInvalid && (
            <FormHelperText variant="outlined">
              {formik.errors.password}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl className={classes.textField} fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                id="remember"
                name="remember"
                onChange={formik.handleChange}
                checked={formik.values.remember}
                color="primary"
                disabled={formik.isSubmitting}
              />
            }
            label="Remember me"
          />
          <FormHelperText>
            Don't tick this box if you're using a public or shared device
          </FormHelperText>
        </FormControl>
        <PortalButton
          className={classes.button}
          data-testid="submit"
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={formik.isSubmitting}
          disableElevation
          fullWidth
        >
          Sign In
        </PortalButton>
        { (false /** todo */) &&
        <div className={classes.actionLink}>
          <Link underline="none" component={BrowserLink} to="/auth/recover">
            Forgot your password?
          </Link>
        </div>
        }
        <div className={classes.actionLink}>
          <Link underline="none" component={BrowserLink} to="/auth/register">
            Need to register?
          </Link>
        </div>
      </form>
    </AuthWrapper>
  );
};
