import { storeAuthInCookie } from "../core/Auth";
import { useAppContext, WorkerStage } from "../core/App";
import { API_URL } from "../core/env";

async function authenticate(emailAddress: string, password: string): Promise<any> {
  const res = await fetch(`${API_URL}/api/auth/worker-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({
      email_address: emailAddress,
      password: password,
    })
  });

  return await res.json();
}

export function useAuthenticate() {
  const { handlers } = useAppContext();

  return (email: string, password: string) =>
    new Promise<any>(async (res, rej) => {
      try {
        const response = await authenticate(email, password)
        if (response.message) {
          rej(response.message);
          return;
        }

        if (!response.token) {
          rej("Invalid response received from the server.");
          return;
        }

        storeAuthInCookie(response.token);
        handlers.setAuth({token: response.token});
        handlers.setAccount({account_id: response.account_id});
        handlers.setWorkerStage(response.finished ? WorkerStage.Complete : WorkerStage.Preregistration);
        res(response);
      } catch (e) {
        console.log(e);
        rej("Invalid response received from the server.");
      }
    });
}
