import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme } from "../core";
import { useFormik } from "formik";
import { createBillingAccount } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "YourPaymentDetails" }
)

const YourPaymentDetailsSchema = Yup.object().shape({
  bank_name: Yup.string().required("We need to know the name of the bank."),
  account_name: Yup.string().required("We need to know the name of the bank account holder."),
  account_number: Yup.string()
    .required("We need to know the accout number for your bank.")
    .min(6, "Please enter a valid account number")
    .max(10, "Please enter a valid account number"),
  sort_code: Yup.string()
    .required("We need to know the sort code for your bank account.")
    .min(6, "Please enter a valid sort code")
    .max(8, "Please enter a valid sort code"),
  contract_type: Yup.string(),
});

type YourPaymentDetailsProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const YourPaymentDetails: React.FC<YourPaymentDetailsProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      bank_name: "",
      account_name: "",
      account_number: "",
      sort_code: "",
      contract_type: "payee",
    },
    validationSchema: YourPaymentDetailsSchema,
    async onSubmit(values) {
      createBillingAccount(token, workerId, values)
        .then(onSuccess)
        .catch(err => alert(`${err}`));
    },
  });

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>

            <Typography gutterBottom variant="h5" component="div">
              Your Bank Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Once you've been sent out to work we will be paying your earnings into your bank account.
              Please provide the bank account details you want paying into.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
              margin="normal"
            >
              <InputLabel htmlFor="bank_name">Name of your bank</InputLabel>
              <OutlinedInput
                id="bank_name"
                name="bank_name"
                label="Name of your bank"
                type="bank_name"
                onChange={formik.handleChange}
                value={formik.values.bank_name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.bank_name && Boolean(formik.errors.bank_name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.bank_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.account_name && Boolean(formik.errors.account_name)}
              margin="normal"
            >
              <InputLabel htmlFor="account_name">Name on the account</InputLabel>
              <OutlinedInput
                id="account_name"
                name="account_name"
                label="Name on the account"
                type="account_name"
                onChange={formik.handleChange}
                value={formik.values.account_name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.account_name && Boolean(formik.errors.account_name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.account_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.account_number && Boolean(formik.errors.account_number)}
              margin="normal"
            >
              <InputLabel htmlFor="account_number">Your account number</InputLabel>
              <OutlinedInput
                id="account_number"
                name="account_number"
                label="Your account number"
                type="account_number"
                onChange={formik.handleChange}
                value={formik.values.account_number}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.account_number && Boolean(formik.errors.account_number)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.account_number}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.sort_code && Boolean(formik.errors.sort_code)}
              margin="normal"
            >
              <InputLabel htmlFor="sort_code">Your sort code</InputLabel>
              <OutlinedInput
                id="sort_code"
                name="sort_code"
                label="Your sort code"
                type="sort_code"
                onChange={formik.handleChange}
                value={formik.values.sort_code}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.sort_code && Boolean(formik.errors.sort_code)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.sort_code}
                </FormHelperText>
              )}
            </FormControl>

          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
