import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  MobileStepper,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import {
  Loading,
  Declarations,
  YourAddress,
  YourEmergencyContact,
  YourPreferences,
  YourPaymentDetails,
  YourPreviousEmployer,
  SignAgreement,
  SubmitNationalInsuranceNumber,
  Assessment,
  UploadDocuments,
} from ".";
import { getStep, getStepResponse } from "./actions";
import { useAppContext, WorkerStage } from "../core/App";
import { API_URL } from "../core/env";

const stepNumber = [
  "add-address",
  "submit-ni",
  "add-emergency-contact",
  "submit-work-preferences",
  "submit-declarations",
  "add-billing-account",
  "add-employment-history",
  "agreements::covid-questionaire",
  "agreements::anti-modern-slavery",
  "agreements::health-assessment",
  "agreements::manual-handling-guide",
  "agreements::work-finder-agreement",
  "agreements::transport-policy",
  "agreements::deducation-form",
  "agreements::key-information-document",
  "agreements::night-shift-questionnaire",
  "upload-documents",
]

export const PreregistrationApp = () => {
  const app = useAppContext();

  const [component, setComponent] = useState(<Loading/>);
  const [step, setStep] = useState<Maybe<getStepResponse>>(null);

  useEffect(() => {
    function onSuccess() {
      setComponent(<Loading/>);
      fetch(`${API_URL}/api/register/status:progress`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${app.auth?.token}`,
          accept: 'application/json'
        }
      }).then(async function (res) {
        if (!res.ok) {
          alert("something went wrong");
          return;
        }

        const data = await res.json();
        setStep(data.step || {name: "complete"});
      }).catch(e => alert("something went wrong"));
    }

    if (step === null) {
      if (!app.auth) {
        return;
      }

      getStep(app.auth.token)
        .then(setStep);
    }

    switch (step?.name) {
      case "add-address":
        setComponent(<YourAddress
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "submit-ni":
        setComponent(<SubmitNationalInsuranceNumber
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "add-emergency-contact":
        setComponent(<YourEmergencyContact
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "submit-work-preferences":
        setComponent(<YourPreferences
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "submit-declarations":
        setComponent(<Declarations
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "add-billing-account":
        setComponent(<YourPaymentDetails
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "add-employment-history":
        setComponent(<YourPreviousEmployer
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::covid-questionaire":
        setComponent(<SignAgreement agreementForm="covid-questionaire"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::anti-modern-slavery":
        setComponent(<SignAgreement agreementForm="anti-modern-slavery"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      // case "agreements::hmrc-confirmation":
      //   setComponent(<SignAgreement agreementForm="hmrc-confirmation"
      //     token={app.auth!.token}
      //     workerId={app.account!.account_id}
      //     onSuccess={onSuccess}
      //   />);
      //   break;
      case "agreements::health-assessment":
        setComponent(<SignAgreement agreementForm="health-assessment"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::manual-handling-guide":
        setComponent(<SignAgreement agreementForm="manual-handling-guide"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::work-finder-agreement":
        setComponent(<SignAgreement agreementForm="work-finder-agreement"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::transport-policy":
        setComponent(<SignAgreement agreementForm="transport-policy"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::deducation-form":
        setComponent(<SignAgreement agreementForm="deducation-form"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::key-information-document":
        setComponent(<SignAgreement agreementForm="key-information-document"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "agreements::night-shift-questionnaire":
        setComponent(<SignAgreement agreementForm="night-shift-questionnaire"
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      // case "assessments::434d5dd8-623b-4d03-bbba-23967eba3db3": // Language & Mathematics
      // case "assessments::fb5a8476-2980-4df5-baec-89133bdb0ad2": // Drivers
        // setComponent(<Assessment
        //   scoreCardId={step.args[0]}
        //   token={app.auth!.token}
        //   workerId={app.account!.account_id}
        //   onSuccess={onSuccess}
        // />);
        // break;
      case "upload-documents":
        setComponent(<UploadDocuments
          token={app.auth!.token}
          workerId={app.account!.account_id}
          onSuccess={onSuccess}
        />);
        break;
      case "complete":
        app.handlers.setWorkerStage(WorkerStage.Complete);
    }
  }, [step, app]);

  return <>
    <AppBar sx={{flexGrow: 1}} position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Registration Form
        </Typography>
        <Button variant="contained" color="info" onClick={() => app.handlers.signout()}>Save & Exit</Button>
      </Toolbar>
    </AppBar>

    { step?.name ?
        <Stack sx={{
          maxWidth: '1200px',
          marginTop: 1,
          marginBottom: 1,
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
        <MobileStepper
          variant="progress"
          steps={stepNumber.length}
          position="static"
          activeStep={stepNumber.indexOf(step.name) + 1}
          sx={{ width: '100%' }}
          backButton={null}
          nextButton={null}
        />
      </Stack> : null}

    {component}
  </>;
}
