import React from "react";
import { Button, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { Link as BrowserLink } from "react-router-dom";
import { AuthWrapper } from "./AuthWrapper";
import { PortalTheme } from "../core";

const useStyles = makeStyles((theme: PortalTheme) => ({
  descriptionText: {
    textAlign: 'justify',
  },
}), {name: "ChooseAction"});

/**
 * ChooseAction component props
 */
export type ChooseActionProps = {};

export const ChooseAction: React.FC<ChooseActionProps> = () => {
  const classes = useStyles();

  return (
    <AuthWrapper>
      <div>
        <p className={classes.descriptionText}>This process is the full registration for GTR Recruitment, you will need around 20-30 minutes to complete it.</p>

        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          justifyContent="space-evenly"
        >
          <Button component={BrowserLink} to="/auth/register" variant="contained">
            Continue
          </Button>
        </Stack>
      </div>
    </AuthWrapper>
  );
};
