import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";

type PasswordInputProps =
  import("@mui/material/OutlinedInput").OutlinedInputProps & {
    displayPassword?: boolean;
  };

export const PasswordInput: React.FC<PasswordInputProps> = ({
  displayPassword,
  ...rest
}) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(displayPassword);
  const type = isPasswordVisible ? "text" : "password";

  const handlePasswordToggle = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  return (
    <OutlinedInput
      {...rest}
      type={type}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handlePasswordToggle}
            edge="end"
          >
            {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
