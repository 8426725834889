import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme } from "../core";
import { useFormik } from "formik";
import { updateWorkerAddress } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "YourAddress" }
)

const YourAddressSchema = Yup.object().shape({
  building: Yup.string().required(),
  street_name: Yup.string().required(),
  town: Yup.string(),
  city: Yup.string(),
  county: Yup.string(),
  post_code: Yup.string().required(),
});

type YourAddressProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const YourAddress: React.FC<YourAddressProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      building: "",
      street_name: "",
      town: "",
      city: "",
      county: "",
      post_code: "",
    },
    validationSchema: YourAddressSchema,
    async onSubmit(values) {
      updateWorkerAddress(token, workerId, values)
        .then(onSuccess)
        .catch(err => alert(`${err}`));
    },
  });

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Address Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please provide your correspondence address.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.building && Boolean(formik.errors.building)}
              margin="normal"
            >
              <InputLabel htmlFor="building">Building</InputLabel>
              <OutlinedInput
                id="building"
                name="building"
                label="Building"
                type="building"
                onChange={formik.handleChange}
                value={formik.values.building}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.building && Boolean(formik.errors.building)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.building}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.street_name && Boolean(formik.errors.street_name)}
              margin="normal"
            >
              <InputLabel htmlFor="street_name">Street</InputLabel>
              <OutlinedInput
                id="street_name"
                name="street_name"
                label="Street"
                type="street_name"
                onChange={formik.handleChange}
                value={formik.values.street_name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.street_name && Boolean(formik.errors.street_name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.street_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.town && Boolean(formik.errors.town)}
              margin="normal"
            >
              <InputLabel htmlFor="town">Town</InputLabel>
              <OutlinedInput
                id="town"
                name="town"
                label="Town"
                type="town"
                onChange={formik.handleChange}
                value={formik.values.town}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.town && Boolean(formik.errors.town)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.town}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.city && Boolean(formik.errors.city)}
              margin="normal"
            >
              <InputLabel htmlFor="city">City</InputLabel>
              <OutlinedInput
                id="city"
                name="city"
                label="City"
                type="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.city && Boolean(formik.errors.city)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.city}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.county && Boolean(formik.errors.county)}
              margin="normal"
            >
              <InputLabel htmlFor="county">County</InputLabel>
              <OutlinedInput
                id="county"
                name="county"
                label="County"
                type="county"
                onChange={formik.handleChange}
                value={formik.values.county}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.county && Boolean(formik.errors.county)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.county}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.post_code && Boolean(formik.errors.post_code)}
              margin="normal"
            >
              <InputLabel htmlFor="post_code">Post Code</InputLabel>
              <OutlinedInput
                id="post_code"
                name="post_code"
                label="Post Code"
                type="post_code"
                onChange={formik.handleChange}
                value={formik.values.post_code}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.post_code && Boolean(formik.errors.post_code)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.post_code}
                </FormHelperText>
              )}
            </FormControl>

          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
