import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme, FormRadioList } from "../core";
import { useFormik } from "formik";
import { updateWorkerDeclarations } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    }
  }),
  { name: "Declarations" }
)

const DeclarationsSchema = Yup.object().shape({
  ability_to_work: Yup.string().required(),
  right_to_work: Yup.string().required(),
  has_criminal_convictions: Yup.string().required(),
  has_impending_prosecutions: Yup.string().required(),
  opted_out_of_work_limit: Yup.string().required(),
});

type DeclarationsProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const Declarations: React.FC<DeclarationsProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      ability_to_work: "",
      right_to_work: "",
      has_criminal_convictions: "",
      has_impending_prosecutions: "",
      opted_out_of_work_limit: "",
    },
    validationSchema: DeclarationsSchema,
    async onSubmit(values) {
      updateWorkerDeclarations(token, workerId, {
        ability_to_work: values.ability_to_work === "yes",
        right_to_work: values.right_to_work === "yes",
        has_criminal_convictions: values.has_criminal_convictions === "yes",
        has_impending_prosecutions: values.has_impending_prosecutions === "yes",
        opted_out_of_work_limit: values.opted_out_of_work_limit === "yes",
      })
      .then(onSuccess)
      .catch(err => alert(`${err}`));
    },
  });

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Declarations
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please answer the following to the best of your ability.
            </Typography>

            <Divider className={classes.divider} />

            <Typography gutterBottom variant="h6" component="div">
              Do you have the ability to work?
            </Typography>

            <FormRadioList
              name="ability_to_work"
              list={[
                {value: "yes", label: "Yes, I have the ability to work."},
                {value: "no", label: "No, I do NOT have the ability to work."},
              ]}
              onChange={formik.handleChange}
              value={formik.values.ability_to_work}
              fullWidth
            />
            {(formik.touched.ability_to_work && Boolean(formik.errors.ability_to_work)) && (
              <FormHelperText variant="outlined">
                {formik.errors.ability_to_work}
              </FormHelperText>
            )}

            <Divider className={classes.divider} />

            <Typography gutterBottom variant="h6" component="div">
              Do you have the right to work in the U.K?
            </Typography>

            <FormRadioList
              name="right_to_work"
              list={[
                {value: "yes", label: "Yes, I am eligible to work in the U.K."},
                {value: "no", label: "No, I am NOT eligible to work in the U.K."},
              ]}
              onChange={formik.handleChange}
              value={formik.values.right_to_work}
              fullWidth
            />
            {(formik.touched.right_to_work && Boolean(formik.errors.right_to_work)) && (
              <FormHelperText variant="outlined">
                {formik.errors.right_to_work}
              </FormHelperText>
            )}

            <Divider className={classes.divider} />

            <Typography gutterBottom variant="h6" component="div">
              Do you have one or more criminal convictions that are unspent under the Rehabilitation of Offenders Act 1974?
            </Typography>

            <FormRadioList
              name="has_criminal_convictions"
              list={[
                {value: "yes", label: "Yes, I have unspent criminal convictions."},
                {value: "no", label: "No, I do NOT have unspent criminal convictions."},
              ]}
              onChange={formik.handleChange}
              value={formik.values.has_criminal_convictions}
              fullWidth
            />
            {(formik.touched.has_criminal_convictions && Boolean(formik.errors.has_criminal_convictions)) && (
              <FormHelperText variant="outlined">
                {formik.errors.has_criminal_convictions}
              </FormHelperText>
            )}

            <Divider className={classes.divider} />

            <Typography gutterBottom variant="h6" component="div">
              Do you have one or more impending prosecutions?
            </Typography>

            <FormRadioList
              name="has_impending_prosecutions"
              list={[
                {value: "yes", label: "Yes, I have impending prosecutions."},
                {value: "no", label: "No, I do not have impending presecutions"},
              ]}
              onChange={formik.handleChange}
              value={formik.values.has_impending_prosecutions}
              fullWidth
            />
            {(formik.touched.has_impending_prosecutions && Boolean(formik.errors.has_impending_prosecutions)) && (
              <FormHelperText variant="outlined">
                {formik.errors.has_impending_prosecutions}
              </FormHelperText>
            )}

            <Divider className={classes.divider} />

            <Typography gutterBottom variant="h6" component="div">
              Do you wish to opt out of the 48 hour working week limit (not applicable for Mobile Workers).
            </Typography>

            <FormRadioList
              name="opted_out_of_work_limit"
              list={[
                {value: "yes", label: "Yes, I wish to work for more then 48 hours a week"},
                {value: "no", label: "No, I would like to work 48 hours or less a week"},
              ]}
              onChange={formik.handleChange}
              value={formik.values.opted_out_of_work_limit}
              fullWidth
            />
            {(formik.touched.opted_out_of_work_limit && Boolean(formik.errors.opted_out_of_work_limit)) && (
              <FormHelperText variant="outlined">
                {formik.errors.opted_out_of_work_limit}
              </FormHelperText>
            )}


          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
