import { getAuthFromCookie, Token, Account } from "../Auth";
import React, { createContext, useState } from "react";

export enum WorkerStage {
  NotRegistered = 0,
  Preregistration = 1,
  Complete = 2,
};

export type AppContextProps = {
  auth: Maybe<Token>;
  account: Maybe<Account>;
  workerStage: Maybe<WorkerStage>,
  handlers: {
    setAccount: (account?: Account) => void;
    setAuth: (auth?: Token) => void;
    setWorkerStage: (status: WorkerStage) => void;
    signout: () => void;
  };
};

export const AppContext = createContext<Maybe<AppContextProps>>(null);

export type AppProviderProps = {
};

export const AppProvider: React.FC<AppProviderProps> = ({
  children,
}) => {
  const [auth, setAuth] = useState<Maybe<Token>>(getAuthFromCookie());
  const [account, setAccount] = useState<Maybe<Account>>();
  const [workerStage, setWorkerStage] = useState<Maybe<WorkerStage>>(WorkerStage.NotRegistered);

  React.useEffect(() => {
    if (!auth) {
      setWorkerStage(WorkerStage.NotRegistered);
      setAccount(null);
    }
  }, [auth]);

  return (
    <AppContext.Provider
      value={{
        auth,
        account,
        workerStage,
        handlers: {
          setAccount,
          setAuth,
          setWorkerStage,
          signout: () => {
            // @todo implement correctly
            window.location.href = '/';
            window.location.reload();
          },
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
