import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  horizontalTable: {
    border: '1px solid #dedede',

    '& .td-disabled': {
      backgroundColor: '#333333',
    },

    '& caption': {
      fontWeight: 'bold',
      margin: theme.spacing(2),
    },

    '& td, th': {
      padding: theme.spacing(2),
    },

    '& tr:not(:last-child) td, tr:not(:last-child) th': {
      borderBottom: '1px solid #dedede',
    },

    '& th': {
      background: '#dedede',
    }
  },
}));

type AgreementSubmissionPayload = {
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h5" component="div">Key Information</Typography>
    <Typography component="div" variant="body1">
    This document sets out key information about your relationship with us and the intermediary or outsourced company used in your engagement, including details about pay, holiday entitlement and other benefits.
    </Typography>

    <Typography component="div" variant="body1">
    For further information please contact <strong>GTR Recruitment, 22 Dartford Road, Leicester, Leicestershire, United Kingdom, LE2 7PQ, Telephone: 01162830007</strong>
    </Typography>

    <Typography component="div" variant="body1">
      The Employment Agency Standards (EAS) Inspectorate is the government authority responsible for the
      enforcement of certain agency worker rights. You can raise a concern with them directly on
      020 7215 5000 or through the Acas helpline on 0300 123 1100, Monday to Friday, 8am to 6pm.
    </Typography>

    <table className={classes.horizontalTable}>
      <caption>GENERAL INFORMATION</caption>
      <tbody>
        <tr>
          <th>Your name:</th>
          <td>GTR Training Limited</td>
        </tr>
        <tr>
          <th>Name of employment business:</th>
          <td>GTR Recruitment trading as GTR Training Limited</td>
        </tr>
        <tr>
          <th>Name of intermediary:</th>
          <td>Moorepay Limited</td>
        </tr>
        <tr>
          <th>Your employer:</th>
          <td>You will be engaged by your outsourced employer. The details of this company will be confirmed with you in a revised version of this document after your first payroll. You will also receive contact information should you wish to speak with them directly.</td>
        </tr>
        <tr>
          <th>Type of contract you will be engaged under:</th>
          <td>Contract of Employment</td>
        </tr>
        <tr>
          <th>Who will be responsible for paying you:</th>
          <td>Your Employer</td>
        </tr>
        <tr>
          <th>How often you will be paid:</th>
          <td>Weekly</td>
        </tr>
      </tbody>
    </table>

    <table className={classes.horizontalTable}>
      <caption>INTERMEDIARY COMPANY PAY INFORMATION</caption>
      <tbody>
        <tr>
          <td colSpan={2}>
            <p>You are being paid through an intermediary company: a third-party organisation that will
                calculate your tax and other deductions and then pay you for the work undertaken for the hirer.
                We will still be finding you assignments.</p>
            <p>The money earned on your assignments will be transferred to the intermediary company as part
                of their income. Your Employer will then pay you your wage. All the deductions made which
                affect your wage are listed below. If you have any queries about these please contact us</p>
          </td>
        </tr>
        <tr>
          <th>Any business connection between the intermediary, the employment business and the person responsible for paying you:</th>
          <td>None</td>
        </tr>
        <tr>
          <th>Expected or minimum gross rate of pay transferred to the intermediary or outsourced employer from us:</th>
          <td>NLW £8.91 or NMW £8.36 per hour</td>
        </tr>
        <tr>
          <th>Any other deductions from intermediary or outsourced employer income (to include amounts or how they are calculated):</th>
          <td>None.</td>
        </tr>
        <tr>
          <th>Expected or minimum rate of pay to you:</th>
          <td>NLW £8.91 or NMW £8.36 per hour, your exact rate will be communicated with you in an assignment letter that you will receive when you choose your assignment.</td>
        </tr>
        <tr>
          <th>Deductions from your wage required by law:</th>
          <td>Tax and National Insurance</td>
        </tr>
        <tr>
          <th>Any other deductions or costs taken from your wage (to include amounts or how they are calculated:</th>
          <td>Pension (if applicable)</td>
        </tr>
        <tr>
          <th>Any fees for goods or services:</th>
          <td>None.</td>
        </tr>
        <tr>
          <th>Holiday entitlement and pay:</th>
          <td>5.6 weeks pro rata (or as otherwise agreed) Paid at an average rate to represent a standard week’s pay</td>
        </tr>
        <tr>
          <th>Additional benefits:</th>
          <td>None.</td>
        </tr>
      </tbody>
    </table>

    <table className={classes.horizontalTable}>
      <caption>EXAMPLE PAY</caption>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td><strong>Intermediary or umbrella fees</strong></td>
          <td><strong>Worker fees</strong></td>
        </tr>
        <tr>
          <th>Example gross rate of pay to intermediary from us:</th>
          <td>£8.91 x 37.5 hours = £334.13</td>
          <td className="td-disabled">&nbsp;</td>
        </tr>
        <tr>
          <th>Deductions from intermediary or outsourced employer income required by law:</th>
          <td>ER NI £22.65
              <br/>ER Pension Contribution (if applicable) £6.42</td>
          <td className="td-disabled">&nbsp;</td>
        </tr>
        <tr>
          <th>Any other deductions or costs taken from intermediary or outsourced employer income:</th>
          <td>None</td>
          <td className="td-disabled">&nbsp;</td>
        </tr>
        <tr>
          <th>Example rate of pay to you:</th>
          <td className="td-disabled">&nbsp;</td>
          <td>£8.91 x 37.5 = £334.13</td>
        </tr>
        <tr>
          <th>Deductions from your pay required by law:</th>
          <td className="td-disabled">&nbsp;</td>
          <td>Tax £ 18.48<br/>EE NI £19.70</td>
        </tr>
        <tr>
          <th>Any other deductions or costs taken from your pay:</th>
          <td className="td-disabled">&nbsp;</td>
          <td>Pension contribution (if applicable) £8.57</td>
        </tr>
        <tr>
          <th>Any fees for goods or services:</th>
          <td className="td-disabled">&nbsp;</td>
          <td>None.</td>
        </tr>
        <tr>
          <th>Example net take home pay:</th>
          <td className="td-disabled">&nbsp;</td>
          <td>£334.13 - £18.48 - £19.70 - £8.57 = £287.38</td>
        </tr>
      </tbody>
    </table>
    <br/>
    <Typography variant="body1">
      I hereby confirm that I have received Key Information Document that sets out key information about my relationship with GTR Recruitment and the intermediary or outsourced company used in my engagement, including details about pay, holiday entitlement and other benefits.
    </Typography>
    <br/>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
