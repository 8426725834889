import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { PortalTheme } from "../theme/muiTheme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: PortalTheme) => ({
  fieldGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type AgreementSubmissionPayload = {
  "question-1": string,
  "question-1-text": string,
  "question-2": string,
  "question-2-text": string,
  "question-3": string,
  "question-3-text": string,
  "question-4": string,
  "question-4-text": string,
  "question-5": string,
  "question-5-text": string,
  "question-6": string,
  "question-6-text": string,
  "question-7": string,
  "question-7-text": string,
  "question-8": string,
  "question-8-text": string,
  "question-9": string,
  "question-9-text": string,
  name: string,
};

type AgreementSubmissionHandler = (payload: AgreementSubmissionPayload) => void;

type AgreementProps = {
  submissionHandler: AgreementSubmissionHandler,
  buttonClassName?: string,
}

const questions = [
  '',
  'Do you suffer from Diabetes, heart or circulatory problems?',
  'Stomach or intestinal problems, such as ulcers?',
  'Any medical conditions which causes difficulty sleeping?',
  'Chronic chest disorders where night time symptoms may be particularly troublesome?',
  'Any medical condition requiring medication or meals is on a strict timetable?',
  'Any mental health problems which may be affected by night work?',
  'Any other medical condition which may affect your ability to work safely at night?',
  'Are you a new or expectant mother?',
  'If you have worked at night before, did this cause any ill health?',
];

export const Agreement: React.FC<AgreementProps> = ({
  submissionHandler,
  buttonClassName
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<AgreementSubmissionPayload>({
    "question-1": "no",
    "question-1-text": "",
    "question-2": "no",
    "question-2-text": "",
    "question-3": "no",
    "question-3-text": "",
    "question-4": "no",
    "question-4-text": "",
    "question-5": "no",
    "question-5-text": "",
    "question-6": "no",
    "question-6-text": "",
    "question-7": "no",
    "question-7-text": "",
    "question-8": "no",
    "question-8-text": "",
    "question-9": "no",
    "question-9-text": "",
    name: "",
  });

  const setValue = (question: string, value: string) => {
    setValues({...values, [question]: value});
  }

  return <>
    <Typography gutterBottom variant="h5" component="div">Night Shift Questionnaire</Typography>
    <Typography component="div" variant="body1">
      If you're going to be doing night shift work we need you to provide the following information.
      <br/>
      To minimise the risk to you and others, we need to ask you the following questions.
    </Typography>
    <Typography variant="h6">Please answer the following:</Typography>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[1]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-1" value={values["question-1"]} onChange={(e)=> setValue("question-1", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-1-text"]}
          onChange={(e) => setValue("question-1-text", e.target.value)}
          disabled={values["question-1"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[2]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-2" value={values["question-2"]} onChange={(e)=> setValue("question-2", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-2-text"]}
          onChange={(e) => setValue("question-2-text", e.target.value)}
          disabled={values["question-2"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>
    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[2]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-2" value={values["question-2"]} onChange={(e)=> setValue("question-2", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-2-text"]}
          onChange={(e) => setValue("question-2-text", e.target.value)}
          disabled={values["question-2"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[3]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-3" value={values["question-3"]} onChange={(e)=> setValue("question-3", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-3-text"]}
          onChange={(e) => setValue("question-3-text", e.target.value)}
          disabled={values["question-3"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[4]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-4" value={values["question-4"]} onChange={(e)=> setValue("question-4", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-4-text"]}
          onChange={(e) => setValue("question-4-text", e.target.value)}
          disabled={values["question-4"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[5]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-5" value={values["question-5"]} onChange={(e)=> setValue("question-5", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-5-text"]}
          onChange={(e) => setValue("question-5-text", e.target.value)}
          disabled={values["question-5"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[6]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-6" value={values["question-6"]} onChange={(e)=> setValue("question-6", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-6-text"]}
          onChange={(e) => setValue("question-6-text", e.target.value)}
          disabled={values["question-6"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[7]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-7" value={values["question-7"]} onChange={(e)=> setValue("question-7", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-7-text"]}
          onChange={(e) => setValue("question-7-text", e.target.value)}
          disabled={values["question-7"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[8]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-8" value={values["question-8"]} onChange={(e)=> setValue("question-8", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-8-text"]}
          onChange={(e) => setValue("question-8-text", e.target.value)}
          disabled={values["question-8"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">{questions[9]}</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <RadioGroup name="question-9" value={values["question-9"]} onChange={(e)=> setValue("question-9", e.target.value)}>
          <FormControlLabel value="no" control={<Radio />} label="No" />
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        </RadioGroup>
        <Typography variant="body2">If yes, please specify details:</Typography>
        <TextField
          value={values["question-9-text"]}
          onChange={(e) => setValue("question-9-text", e.target.value)}
          disabled={values["question-9"] === "no"}
          fullWidth
          multiline
          rows={4}
        />
      </FormControl>
    </div>

    <div className={classes.fieldGroup}>
      <FormLabel component="legend">Please provide your full name.</FormLabel>
      <FormControl component="fieldset" fullWidth>
        <TextField
          value={values.name}
          onChange={(e) => setValue("name", e.target.value)}
          fullWidth
        />
      </FormControl>
    </div>
    <Button variant="contained" className={buttonClassName} onClick={_ => submissionHandler(values)}>Submit</Button>
  </>
};
