import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme } from "../core";
import { useFormik } from "formik";
import { submitNationalInsuranceNumber } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "SubmitNationalInsuranceNumber" }
)

const SubmitNationalInsuranceNumberSchema = Yup.object().shape({
  name: Yup
    .string()
    .length(9, "Please enter a valid national insurance number for example: AA111111A")
    .required("Please enter your national insurance number"),
});

type SubmitNationalInsuranceNumberProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const SubmitNationalInsuranceNumber: React.FC<SubmitNationalInsuranceNumberProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      value: "",
    },
    validationSchema: SubmitNationalInsuranceNumberSchema,
    async onSubmit(values) {
      submitNationalInsuranceNumber(token, workerId, values.value)
        .then(onSuccess)
        .catch(err => alert(`${err}`));
    },
  });

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Your National Insurance Number
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Please provide your national insurance number issued by HM Revenue and Customs.
              If you do not have one yet please press skip.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.value && Boolean(formik.errors.value)}
              margin="normal"
            >
              <InputLabel htmlFor="value">National Insurance Number</InputLabel>
              <OutlinedInput
                id="value"
                name="value"
                label="National Insurance Number"
                type="value"
                onChange={formik.handleChange}
                value={formik.values.value}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.value && Boolean(formik.errors.value)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.value}
                </FormHelperText>
              )}
            </FormControl>

          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>

            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="button"
              onClick={onSuccess}
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Skip and add later
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
