import React from "react";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardActions,
  Link,
  Stack,
  Toolbar,
  Typography,
  FormControl,
  FormLabel,
  OutlinedInput
} from "@mui/material";
import { FormRadioList } from "../core";
import { useAppContext } from "../core/App";

export const DashboardApp = () => {
  const app = useAppContext();

  const [howEasy, setHowEasy] = React.useState<string>("");
  const [howWasExp, setHowWasExp] = React.useState<string>("");
  const [comments, setComments] = React.useState<string>("");
  const [hasReviewed, setHasReviewed] = React.useState<boolean>(false);

  const submitQuestions = () => {
    fetch("https://api.acceptrec.co.uk/api/register/questionnaire", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "accept": "application/json",
        "authorization": `Bearer ${app.auth?.token}`
      },
      body: JSON.stringify({
        how_easy: howEasy,
        how_was_exp: howWasExp,
        comments: comments,
      })
    }).catch(console.error);
    setHasReviewed(true);
  };

  return <>
  <AppBar sx={{flexGrow: 1}} position="static">
    <Toolbar>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Completed
      </Typography>
    </Toolbar>
  </AppBar>
  <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
    <Card hidden={hasReviewed}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Please help us by answering a few questions
        </Typography>

        <FormRadioList
          fullWidth
          label="How easy was it to register with us today?"
          value={howEasy}
          onChange={v => setHowEasy(v.target.value)}
          list={[
            {value: "1", label: "Very Easy"},
            {value: "2", label: "Easy"},
            {value: "3", label: "Neutral"},
            {value: "4", label: "Not Easy"},
            {value: "5", label: "Hard"},
          ]}
        />

        <FormRadioList
          fullWidth
          label="How has your experience been so far with GTR Recruitment?"
          value={howWasExp}
          onChange={v => setHowWasExp(v.target.value)}
          list={[
            {value: "1", label: "Very Good"},
            {value: "2", label: "Good"},
            {value: "3", label: "Neutral"},
            {value: "4", label: "Not Good"},
            {value: "5", label: "Terrible"},
          ]}
        />

        <FormControl fullWidth>
          <FormLabel>Any suggestions on how we could improve the experience?</FormLabel>
          <OutlinedInput
            value={comments}
            onChange={e => setComments(e.target.value.substr(0, 500))}
            rows={5}
            multiline
            fullWidth
          />
        </FormControl>

      </CardContent>
      <CardActions>
        <Button
          onClick={submitQuestions}
          variant="contained"
        >Submit</Button>
      </CardActions>
    </Card>

    <Card sx={hasReviewed ? {} : {marginTop: 1}}>
      <CardContent>
        <Typography variant="body1">
          You've completed the registration form. You can now login to the worker dashboard using your
          e-mail address and password.
        </Typography>
        <Typography variant="body1">
          The worker portal allows you to manage your worker profile going forward.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          LinkComponent={Link}
          href="https://portal.acceptrec.co.uk/"
          variant="contained"
        >{hasReviewed ? "Goto dashboard" : "Skip and open dashboard"}</Button>
      </CardActions>
    </Card>
  </Stack>
</>;
}
