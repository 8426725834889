import React from "react";
import {
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PortalTheme } from "../core";
import {
  AntiModernSlaveryAgreement,
  CovidQuestionnaireAgreement,
  HMRCConfirmationAgreement,
  HealthAssessmentAgreement,
  ManualHandlingGuideAgreement,
  WorkFinderAgreement,
  TransportPolicyAgreement,
  DeductionFormAgreement,
  KeyInformationDocumentAgreement,
  NightShiftQuestionnaireAgreement
} from "../core/Agreements";
import { submitAgreement } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    }
  }),
  { name: "SignAgreement" }
)

type SignAgreementProps = {
  agreementForm: string,
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const SignAgreement: React.FC<SignAgreementProps> = ({
  agreementForm,
  token,
  workerId,
  onSuccess,
}) => {
  const classes = useStyles();
  let content = null;

  const submit = (values: any) => {
    if (values.name === "") {
      alert("Please enter your name.");
      return;
    }

    submitAgreement(token, workerId, agreementForm, values)
    .then(onSuccess)
    .catch(err => alert(`${err}`))
  };

  switch (agreementForm) {
    case 'covid-questionaire':
      content = <CovidQuestionnaireAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'anti-modern-slavery':
      content = <AntiModernSlaveryAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'hmrc-confirmation':
      content = <HMRCConfirmationAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'health-assessment':
      content = <HealthAssessmentAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'manual-handling-guide':
      content = <ManualHandlingGuideAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'work-finder-agreement':
      content = <WorkFinderAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'transport-policy':
      content = <TransportPolicyAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'deducation-form':
      content = <DeductionFormAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'key-information-document':
      content = <KeyInformationDocumentAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

    case 'night-shift-questionnaire':
      content = <NightShiftQuestionnaireAgreement
        submissionHandler={submit}
        buttonClassName={classes.button}
      />;
      break;

  }

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <Card>
        <CardContent>
          {content}
        </CardContent>
      </Card>
    </Stack>
  </>
};
