import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme, FormCheckList } from "../core";
import { useFormik } from "formik";
import * as constants from "../core/constants";
import { updateWorkerPreferences } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "YourPreferences" }
)

const YourPreferencesSchema = Yup.object().shape({
  preferred_location: Yup.string().required("Please enter a location"),
  work_types: Yup.array().of(Yup.string()).min(1, "Please provide at least one type of work"),
  skills: Yup.array().of(Yup.string()).min(1, "Please provide at least one type of skill"),
  experience: Yup.string(),
  preferences: Yup.string(),
});

type YourPreferencesProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const YourPreferences: React.FC<YourPreferencesProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      preferred_location: "",
      preferences: "",
      experience: "",
      work_types: [],
      skills: [],
    },
    validationSchema: YourPreferencesSchema,
    async onSubmit(values) {
      updateWorkerPreferences(token, workerId, values)
        .then(onSuccess)
        .catch(err => alert(`${err}`));
    },
  });

  const skillsShown: any = [];

  formik.values.work_types.forEach(t =>
    Object.entries(constants.SKILLS_GROUPED[t]).forEach((v) => skillsShown.push(v[1]))
  )

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Your Work Preferences
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.preferred_location && Boolean(formik.errors.preferred_location)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Preferred Location</InputLabel>
              <OutlinedInput
                id="preferred_location"
                name="preferred_location"
                label="Preferred Location"
                type="preferred_location"
                onChange={formik.handleChange}
                value={formik.values.preferred_location}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.preferred_location && Boolean(formik.errors.preferred_location)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.preferred_location}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.experience && Boolean(formik.errors.experience)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Experience</InputLabel>
              <OutlinedInput
                id="experience"
                name="experience"
                label="Experience"
                type="experience"
                onChange={formik.handleChange}
                value={formik.values.experience}
                disabled={formik.isSubmitting}
                rows={4}
                multiline
              />
              {(formik.touched.experience && Boolean(formik.errors.experience)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.experience}
                </FormHelperText>
              )}
            </FormControl>

            <Divider className={classes.divider} />
            <Typography gutterBottom variant="h5" component="div">
              What types of work are you looking for?
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We want to be able to make sure we contact you about jobs most suitable to you.
            </Typography>

            <FormCheckList
              name="work_types"
              list={constants.WORK_TYPES}
              onChange={formik.handleChange}
              value={formik.values.work_types}
              fullWidth
            />
            {(formik.touched.work_types && Boolean(formik.errors.work_types)) && (
              <FormHelperText variant="outlined">
                {formik.errors.work_types}
              </FormHelperText>
            )}

            {formik.values.work_types.length > 0 ?
              <>
                <Typography variant="body2" color="text.secondary">
                  Please select the skills do you have:
                </Typography>

                <FormCheckList
                  name="skills"
                  list={skillsShown}
                  onChange={formik.handleChange}
                  value={formik.values.skills}
                  fullWidth
                  />
              </>
            : null }

            {(formik.touched.skills && Boolean(formik.errors.skills)) && (
              <FormHelperText variant="outlined">
                {formik.errors.skills}
              </FormHelperText>
            )}

          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
