import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme } from "../core";
import { useFormik } from "formik";
import { recordEmploymentHistory } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "YourPreviousEmployer" }
)

const YourPreviousEmployerSchema = Yup.object().shape({
  job_title: Yup.string(),
  hourly_wage: Yup.string(),
  company_name: Yup.string(),
  company_address: Yup.string(),
  company_postcode: Yup.string(),
  contact_name: Yup.string(),
  contact_phone_number: Yup.string(),
  leave_reason: Yup.string(),
  agency_name: Yup.string(),
  agency_staff_count: Yup.string(),
});

type YourPreviousEmployerProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const YourPreviousEmployer: React.FC<YourPreviousEmployerProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      job_title: "",
      hourly_wage: "",
      company_name: "",
      company_address: "",
      company_postcode: "",
      contact_name: "",
      contact_phone_number: "",
      leave_reason: "",
      agency_name: "",
      agency_staff_count: "",
    },
    validationSchema: YourPreviousEmployerSchema,
    async onSubmit(values) {
      recordEmploymentHistory(token, workerId, values)
      .then(onSuccess)
      .catch(err => alert(`${err}`));
    },
  });

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Your Previous Employment
            </Typography>
            <Typography variant="body2" color="text.secondary">
              If you've had any previous employment then please fill out the form below.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.job_title && Boolean(formik.errors.job_title)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Job Title</InputLabel>
              <OutlinedInput
                id="job_title"
                name="job_title"
                label="Job Title"
                type="job_title"
                onChange={formik.handleChange}
                value={formik.values.job_title}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.job_title && Boolean(formik.errors.job_title)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.job_title}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.hourly_wage && Boolean(formik.errors.hourly_wage)}
              margin="normal"
            >
              <InputLabel htmlFor="name">What was your hourly wage in &pound;s</InputLabel>
              <OutlinedInput
                id="hourly_wage"
                name="hourly_wage"
                label="What was your hourly wage in &pound;s"
                type="hourly_wage"
                onChange={formik.handleChange}
                value={formik.values.hourly_wage}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.hourly_wage && Boolean(formik.errors.hourly_wage)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.hourly_wage}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.company_name && Boolean(formik.errors.company_name)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Name of the company you worked for</InputLabel>
              <OutlinedInput
                id="company_name"
                name="company_name"
                label="Name of the company you worked for"
                type="company_name"
                onChange={formik.handleChange}
                value={formik.values.company_name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.company_name && Boolean(formik.errors.company_name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.company_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.company_address && Boolean(formik.errors.company_address)}
              margin="normal"
            >
              <InputLabel htmlFor="name">First line of the company address</InputLabel>
              <OutlinedInput
                id="company_address"
                name="company_address"
                label="First line of the company address"
                type="company_address"
                onChange={formik.handleChange}
                value={formik.values.company_address}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.company_address && Boolean(formik.errors.company_address)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.company_address}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.company_postcode && Boolean(formik.errors.company_postcode)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Postcode for the company</InputLabel>
              <OutlinedInput
                id="company_postcode"
                name="company_postcode"
                label="Postcode for the company"
                type="company_postcode"
                onChange={formik.handleChange}
                value={formik.values.company_postcode}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.company_postcode && Boolean(formik.errors.company_postcode)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.company_postcode}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.contact_name && Boolean(formik.errors.contact_name)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Who is best to contact at the company</InputLabel>
              <OutlinedInput
                id="contact_name"
                name="contact_name"
                label="Who is best to contact at the company"
                type="contact_name"
                onChange={formik.handleChange}
                value={formik.values.contact_name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.contact_name && Boolean(formik.errors.contact_name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.contact_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.contact_phone_number && Boolean(formik.errors.contact_phone_number)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Company phone number</InputLabel>
              <OutlinedInput
                id="contact_phone_number"
                name="contact_phone_number"
                label="Company phone number"
                type="contact_phone_number"
                onChange={formik.handleChange}
                value={formik.values.contact_phone_number}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.contact_phone_number && Boolean(formik.errors.contact_phone_number)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.contact_phone_number}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.leave_reason && Boolean(formik.errors.leave_reason)}
              margin="normal"
            >
              <InputLabel htmlFor="name">What was the reason you left?</InputLabel>
              <OutlinedInput
                id="leave_reason"
                name="leave_reason"
                label="What was the reason you left?"
                type="leave_reason"
                onChange={formik.handleChange}
                value={formik.values.leave_reason}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.leave_reason && Boolean(formik.errors.leave_reason)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.leave_reason}
                </FormHelperText>
              )}
            </FormControl>

            <Divider className={classes.divider} />
            <Typography gutterBottom variant="h5" component="div">
              Did you work for an agency?
            </Typography>
            <Typography variant="body2" color="text.secondary">
              If your previous employment wasn't with an agency please skip the following questions.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.agency_name && Boolean(formik.errors.agency_name)}
              margin="normal"
            >
              <InputLabel htmlFor="name">What was the name of the agency?</InputLabel>
              <OutlinedInput
                id="agency_name"
                name="agency_name"
                label="What was the name of the agency?"
                type="agency_name"
                onChange={formik.handleChange}
                value={formik.values.agency_name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.agency_name && Boolean(formik.errors.agency_name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.agency_name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.agency_staff_count && Boolean(formik.errors.agency_staff_count)}
              margin="normal"
            >
              <InputLabel htmlFor="name">How many staff worked at the agency?</InputLabel>
              <OutlinedInput
                id="agency_staff_count"
                name="agency_staff_count"
                label="How many staff worked at the agency?"
                type="agency_staff_count"
                onChange={formik.handleChange}
                value={formik.values.agency_staff_count}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.agency_staff_count && Boolean(formik.errors.agency_staff_count)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.agency_staff_count}
                </FormHelperText>
              )}
            </FormControl>

          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
