import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { PortalButton, PortalTheme } from "../core";
import { useFormik } from "formik";
import { updateWorkerEmergencyContact } from "./actions";

const useStyles = makeStyles(
  (theme: PortalTheme) => ({
    textField: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(2, 0),
    },
    actionLink: {
      marginTop: theme.spacing(1),
    },
    invalidError: {
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(1, 0),
    }
  }),
  { name: "YourEmergencyContact" }
)

const YourEmergencyContactSchema = Yup.object().shape({
  name: Yup.string().required("Please enter their name"),
  phone_number: Yup.string().required("Please enter their phone number."),
});

type YourEmergencyContactProps = {
  token: string,
  workerId: string,
  onSuccess: () => void,
};

export const YourEmergencyContact: React.FC<YourEmergencyContactProps> = ({token, workerId, onSuccess}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_number: "",
    },
    validationSchema: YourEmergencyContactSchema,
    async onSubmit(values) {
      updateWorkerEmergencyContact(token, workerId, values)
        .then(onSuccess)
        .catch(err => alert(`${err}`));
    },
  });

  return <>
    <Stack sx={{maxWidth: '1200px', marginTop: 1, marginLeft: 'auto', marginRight: 'auto'}}>
      <form onSubmit={formik.handleSubmit}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Emergency Contact
            </Typography>
            <Typography variant="body2" color="text.secondary">
              If there is any issues whilst you're out at work, who would we need to notify.
            </Typography>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.name && Boolean(formik.errors.name)}
              margin="normal"
            >
              <InputLabel htmlFor="name">Contact Name</InputLabel>
              <OutlinedInput
                id="name"
                name="name"
                label="Contact Name"
                type="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.name && Boolean(formik.errors.name)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.name}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
              error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
              margin="normal"
            >
              <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
              <OutlinedInput
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                type="phone_number"
                onChange={formik.handleChange}
                value={formik.values.phone_number}
                disabled={formik.isSubmitting}
              />
              {(formik.touched.phone_number && Boolean(formik.errors.phone_number)) && (
                <FormHelperText variant="outlined">
                  {formik.errors.phone_number}
                </FormHelperText>
              )}
            </FormControl>

          </CardContent>
          <CardActions>
            <PortalButton
              className={classes.button}
              data-testid="submit"
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={formik.isSubmitting}
              disableElevation
              fullWidth
            >
              Continue
            </PortalButton>
          </CardActions>
        </Card>
      </form>
    </Stack>
  </>
};
