import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import './index.css';
import { muiTheme } from "./core/theme/muiTheme";
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './core/App';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Suspense fallback={<div>loading</div>}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <AppProvider>
              <App />
            </AppProvider>
          </LocalizationProvider>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
