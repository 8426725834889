import { Grid, Link, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import logopng from "./logo.png";

const useStyles = makeStyles<Theme>(
  (theme) => ({
    container: {
      height: "100vh",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      textAlign: "center",
      marginBottom: theme.spacing(3),
    },
    content: {
      maxWidth: 380,
      marginTop: "auto",
      padding: theme.spacing(4),
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.common.white,
      },
    },
    relatedLinks: {
      marginTop: theme.spacing(2),
      marginBottom: "auto",
      [theme.breakpoints.up("sm")]: {
        marginTop: "auto",
        marginBottom: 0,
      },
    },
    relatedAnchor: {
      padding: theme.spacing(1),
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1),
        textAlign: "left",
      },
    },
  }),
  {
    name: "AuthWrapper",
  }
);

type AuthWrapperProps = {};

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.wrapper} item xs={12}>
        <div className={classes.content}>
          <div className={classes.logo}>
            <img
              style={{width: '100%'}}
              src={logopng}
              alt="GTR Recruitment"
            />
          </div>
          {children}
        </div>
        <div className={classes.relatedLinks}>
          <Grid container>
            <Grid item xs={12} sm="auto">
              <div className={classes.relatedAnchor}>
                <Link color="textPrimary" href="https://gtrrecruitment.com/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
