import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { PortalTheme } from "../theme/muiTheme";

const useStyles = makeStyles((theme: PortalTheme) => ({
  formCheckListWrapper: {
    marginBottom: theme.spacing(1),
  },
  formCheckListLabel: {
    marginBottom: theme.spacing(1),
  },
  formCheckListControl: {
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 0.5),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

type FormCheckListItem = {
  value: string,
  label: string,
};

type FormCheckListProps = {
  className?: string,
  label?: string,
  list: FormCheckListItem[],
  value: string[],
  helperText?: string,
  onBlur?: () => void,
  onChange?: (e: React.ChangeEvent<any>) => void,
  name?: string,
  fullWidth: boolean,
};

export const FormCheckList: React.FC<FormCheckListProps> = ({
  className,
  label,
  list,
  value,
  helperText,
  onBlur,
  onChange,
  name,
  fullWidth,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={clsx(className, classes.formCheckListWrapper)}
      component="fieldset"
      fullWidth={fullWidth}
    >
      <FormLabel className={classes.formCheckListLabel} component="legend">
        {label}
      </FormLabel>
      <FormGroup>
        {list.map((item) => (
          <FormControlLabel
            className={classes.formCheckListControl}
            key={item.value}
            name={name}
            control={
              <Checkbox
                checked={value.includes(item.value)}
                value={item.value}
                onChange={onChange}
                onBlur={onBlur}
              />
            }
            label={item.label}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
