import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { PortalTheme } from "../theme/muiTheme";

const useStyles = makeStyles((theme: PortalTheme) => ({
  formRadioListWrapper: {
    marginBottom: theme.spacing(1),
  },
  formRadioListLabel: {
    marginBottom: theme.spacing(1),
  },
  formRadioListControl: {
    margin: theme.spacing(0, 0, 1),
    padding: theme.spacing(1, 0.5),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
  },
  formRadioListControlChecked: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  formRadioListControlError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

type FormRadioListItem = {
  value: string,
  label: string,
};

type FormRadioListProps = {
  className?: string,
  label?: string,
  list: FormRadioListItem[],
  value: string,
  helperText?: string,
  onBlur?: () => void,
  onChange?: (e: React.ChangeEvent<any>) => void,
  name?: string,
  listLength?: number,
  error?: boolean,
  fullWidth?: boolean,
};

export const FormRadioList: React.FC<FormRadioListProps> = ({
  className,
  label,
  list,
  value,
  helperText,
  onBlur,
  onChange,
  name,
  listLength = 8,
  error,
  fullWidth,
}) => {
  const classes = useStyles();
  const [showAll, setShowAll] = React.useState(list.length <= listLength);

  return (
    <FormControl
      className={clsx(className, classes.formRadioListWrapper)}
      component="fieldset"
      error={error}
      fullWidth={fullWidth}
    >
      <FormLabel className={classes.formRadioListLabel} component="legend">
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        {(showAll ? list : list.slice(0, listLength)).map((item) => (
          <FormControlLabel
            className={clsx(classes.formRadioListControl, {
              [classes.formRadioListControlChecked]: item.value === value,
              [classes.formRadioListControlError]: error,
            })}
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!showAll && (
        <Button color="primary" variant="text" onClick={() => setShowAll(true)}>
          See all options
        </Button>
      )}
    </FormControl>
  );
};
