import { API_URL } from "../core/env";
import { uuid } from 'uuidv4';

export type getStepResponse = {
  name: string,
  args: Array<string>,
}

export async function getStep(token: string): Promise<Maybe<getStepResponse>> {
  try {
    const res = await fetch(`${API_URL}/api/register/status`, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${token}`,
      },
    });

    if(!res.ok) {
      return null;
    }

    const data = await res.json();
    return data.step;
  } catch (_) {
    return null;
  }
};

export const createBillingAccount = (token: string, workerId: string, data: any) => {
  data['account_id'] = uuid();
  data['worker_id'] = workerId;

  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/billing/accounts`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
};



export function updateWorkerDetails(token: string, workerId: string, data: any) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/update`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({worker_id: workerId, ...data}),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
};

export function updateWorkerDeclarations(token: string, workerId: string, data: any) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/update-declarations`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({worker_id: workerId, ...data}),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
};

export function updateWorkerEmergencyContact(token: string, workerId: string, data: any) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/update-emergency-contact`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({worker_id: workerId, ...data}),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
};

export function updateWorkerPreferences(token: string, workerId: string, data: any) {
  // have created an inconsistency between job_types and work_types.
  if (!data.work_types) {
    data.work_types = data.job_types || [];
  }

  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/update-work-preferences`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({worker_id: workerId, ...data}),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
};

export function updateWorkerAddress(token: string, workerId: string, address: any) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/update-correspondence-address`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({worker_id: workerId, address: address}),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
};

export function getFileType(file: any) {
  return file.type || "text/plain";
}

export function uploadFile(token: string, workerId: string, type: string, file: any) {
  return new Promise((res, rej) => {
    const data = {
      worker_id: workerId,
      file_name: `${type}.${file.name.split('.').pop()}`,
    };

    fetch(`${API_URL}/api/worker/create-upload-link`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token}`,
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((resp) => {
      if (!resp.ok) {
        resp.json().then(rej);
        return;
      }

      resp.json().then((data) => {
        fetch(data.link, {
          method: "PUT",
          headers: {
            "content-type": getFileType(file),
          },
          body: file,
        })
        .then(resp => {
          if (!resp.ok) {
            rej(resp);
            return;
          }
          res(resp);
        }).catch(rej);
      });
    });
  });
}

export function recordEmploymentHistory(token: string, workerId: string, data: any) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/employment-history`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token}`,
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify({...data, worker_id: workerId}),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });
}

export function submitAgreement(token: string, workerId: string, agreementName: string, values: any) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/agreements`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token}`,
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify({
        submission_id: uuid(),
        worker_id: workerId,
        agreement_id: agreementName,
        printed_name: values.name || "N/A",
        data: values,
      }),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });

}

export function submitNationalInsuranceNumber(token: string, workerId: string, value: string) {
  return new Promise((res, rej) => {
    fetch(`${API_URL}/api/worker/submit-national-insurance-number`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token}`,
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify({
        worker_id: workerId,
        value,
      }),
    })
    .then((result) => {
      if (result.ok) {
        res(null);
        return
      }

      result.json().then(rej);
    })
    .catch(rej);
  });

}

export const submitAnswers = (token: string, workerId: string, scoreCardId: string, startedAt: number, answers: any) => {
  return new Promise(async (res, rej) => {
    fetch(`${API_URL}/api/worker/score-cards/${scoreCardId}/assessment`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        worker_id: workerId,
        started_at: startedAt,
        answers: answers,
      }),
    })
    .then(async (result) => {
      if (!result.ok) {
        rej();
        return;
      }

      res((await result.json()).passed);
    })
    .catch(rej);
  });
};
