import Cookie from "js-cookie";

export type Token = {
  token: string;
};

export type Account = {
  account_id: string,
};

/**
 * Store auth credentials in a cookie.
 * @param auth the response from login api to store
 * @param expiry number of days until expiry or Date
 */
export function storeAuthInCookie(
  token: string,
  expiry: number | Date = new Date(new Date().getTime() + 1200 * 1000)
): void {
  Cookie.set("sid", JSON.stringify({ token }), {
    secure: true,
    sameSite: "strict",
    expires: expiry,
  });
}

export function removeAuthFromCookie(): void {
  Cookie.remove("sid");
}

/**
 * Get auth credentials.
 */
export function getAuthFromCookie(): Token | null {
  const value = Cookie.get("sid");
  if (!value) {
    return null;
  }
  return JSON.parse(value);
}

/**
 * Get the token from stored auth.
 */
export function getTokenFromCookie(): string | null {
  const auth = getAuthFromCookie();

  if (auth) {
    return auth.token;
  }

  return null;
}

export function storeEmailInCookie(email: string): void {
  Cookie.set("email", email, {
    secure: true,
    sameSite: "strict",
    expires: 90,
  });
}

export function getEmailFromCookie(): string | undefined {
  return Cookie.get("email");
}

export function removeEmailFromCookie(): void {
  Cookie.remove("email");
}

export function getDecodedToken(token: string): string {
  return atob(token.split(".")[1]);
}

export function getTokenExpiryTime(token: string): number {
  const decodedToken = getDecodedToken(token);
  return JSON.parse(decodedToken)?.exp;
}

export function getSecondsUntilRenew(token?: string | null): Date | null {
  if (!token) {
    return null;
  }

  const expiry = getTokenExpiryTime(token);
  const timeRemaining = expiry - new Date().getTime() / 1000;

  // Check if token is within period that renewal is allowed
  // and we can renew it before expiration.
  if (timeRemaining < 120) {
    return new Date();
  }

  console.log(timeRemaining);

  return null;
}
